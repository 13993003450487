import React from "react";
import { connect } from "react-redux";
import { DashboardHeader } from "../Header";
import requestQuery from "../../utils/queries/requestQuery";
import { onRemoveNotification } from "../../actions/Notification";
import { Loading } from "../Loader";
import { getStorage } from "../../utils/storage";
import { UserSMProfile } from "../User";
import language from "../../language";
import { QuestionnaireAnswers } from "./QuestionnaireAnswers";
import { Attachments } from "../Attachment";
import { Comment } from "./Comment";
import { Modal } from "../Modal";
import { ApproverApprove, ApproverReject } from "../Approvers";
import { SubApproverApprove, SubApproverReject } from "../SubApprovers";
import socket from "../../utils/socket/connection";
import Sidebar from "./Sidebar";
import "./styles.css";
import icons from "../../constants/icons";

class SingleRequest extends React.Component {
  constructor() {
    super();

    this.state = {
      isLoading: false,
      questionnaireAnswers: [],
      data: {},
      attachments: [],
      error: {},
      showApproverActions: false,
      comments: [],
      commentText: "",
      approver: {},
      user: {},
      attachments: [1],
      view: "grid",
    };

    this.fileRef = React.createRef();
  }

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user }, () => {
      this.getData(true);
    });

    socket.on("notification", async () => {
      this.getData(false);
    });
  };

  handleShowModal(modal, modalTitle, selected_data = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_data,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target.value,
      error,
    });
  }

  getData = async (isLoading) => {
    console.log("====================================");
    console.log("start fetch request info...");
    console.log("====================================");
    try {
      let { page, limit, user, showApproverActions } = this.state;
      const { defaultLanguage, requestId } = this.props;

      this.setState({ isLoading, showApproverActions: false, comment: "" });

      //read notification: check if notification exist in notification storage redux, then remove it, this will count down notifications
      this.props.dispatch(onRemoveNotification(requestId));

      const { data } = await requestQuery(defaultLanguage, {
        page,
        limit,
        id: requestId,
      });

      let res = {};

      if (data?.length > 0) {
        res = data[0];
      }

      let attachments = [],
        questionnaireAnswers = [],
        totalSubApproversDone = 0,
        comments = [],
        approver = {};

      for (let el of res.questionnaireAnswer || []) {
        if (el && el.answerType && el.answerType === "file") {
          attachments.push(el);
        } else {
          questionnaireAnswers.push(el);
        }
      }

      if (res?.subApprovals?.length > 0) {
        const highestLevel = Math.max(...res.subApprovals?.map((o) => o.level));

        const highestIndex = res.subApprovals.findIndex(
          (o) => o.level === highestLevel
        );

        console.log("highestIndex", highestIndex);

        if (
          res.subApprovals[highestIndex] &&
          res.subApprovals[highestIndex].action
        ) {
          totalSubApproversDone = res.subApprovals.length;
        } else {
          res.subApprovals.forEach((el) => {
            if (el.actionAt && el.actionAt !== "") {
              totalSubApproversDone += 1;
            }

            el.isSubApproval = true;
          });
        }
      }

      //check if highest levels has approved or not then the lowest cant approve
      // console.log("my level***", approver.level);
      // console.log("other levels***", el.level);
      // approver.level < el.level && console.log("highest***", el.level);

      // if (approver.level < el.level && el.actionAt && el.actionAt !== "") {
      //   console.log("highest level***", el);
      //   this.setState({ showApproverActions: false });
      // }

      //retrieve all comments from request

      const approvers = [...res.subApprovals, ...res.approvals];

      approvers.forEach((el, idx) => {
        if (
          (el.actionComment && el.actionComment !== "") ||
          el.questionnaireAction?.length > 0
        ) {
          comments.push({
            text: el.actionComment,
            date: el.actionAt,
            user: el.account,
            questionnaireAction: el.questionnaireAction,
            actionStatus:
              el.action === "Approval"
                ? "Approved"
                : el.action === "Rejection"
                ? "Rejected"
                : el.action,
          });
        }

        if (el?.account?._id === this.state?.user?.id) {
          approver = el;
        }

        if (
          !el?.account &&
          Object.keys(approver).length === 0 &&
          el?.accessRole?._id === this.state?.user?.accessRole?._id
        ) {
          approver = el;
        }

        //check if highest levels has approved or not then the lowest cant approve
        // console.log("my level***", approver.level);
        // console.log("other levels***", el.level);
        // approver.level < el.level && console.log("highest***", el.level);
      });

      // console.log(user.id);
      // console.log(approver);

      // console.log(
      //   " && approver.account._id === user.id",
      //   approver.account._id === user.id
      // );
      // console.log(
      //   "is suba p",
      //   approver.isSubApproval ||
      //     (!approver.isSubApproval &&
      //       totalSubApproversDone === res.subApprovals.length)
      // );

      console.log("=================approver===================");
      console.log(approver);
      console.log("====================================");

      const statusCheck =
        res.status === "pending" &&
        approver?.action?.toLowerCase() !== "approved" &&
        approver?.action?.toLowerCase() !== "rejected";

      const returnedCheck =
        res.status !== "requestedchanges" &&
        approver?.action?.toLowerCase() === "requestedchanges";

      const accountCheck =
        (approver.account && approver.account._id === user.id) ||
        (!approver.account &&
          approver.accessRole &&
          user.accessRole &&
          approver.accessRole._id === user.accessRole._id);

      const subApproverCheck =
        approver.isSubApproval ||
        (!approver.isSubApproval &&
          totalSubApproversDone === res.subApprovals.length);

      console.log("====================================");
      console.log({
        accountCheck,
        statusCheck,
      });
      console.log("====================================");

      if (accountCheck && statusCheck && subApproverCheck) {
        showApproverActions = true;
      } else {
        showApproverActions = false;
      }

      approvers.forEach((el) => {
        console.log(
          "  approver.level !== el.level 2",
          approver.level !== el.level
        );
        // if (
        //   showApproverActions &&
        //   el.action &&
        //   el.action !== "" &&
        //   approver.level !== el.level &&
        //   approver.level < el.level
        // ) {
        //   console.log("higher level has approved");
        //   showApproverActions = false;
        // }
      });

      console.log("====================================");
      console.log({
        showApproverActions,
        statusCheck,
      });
      console.log("====================================");

      //update state
      this.setState({
        data: res,
        isLoading: false,
        attachments,
        questionnaireAnswers,
        comments,
        approver,
        showApproverActions,
        commentText: "",
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
    }
  };

  fileUploadAction() {
    this.fileRef.current.click();
  }

  handleFileChange(e) {
    this.setState({
      file: e.target.files[0],
    });
  }

  handleRemoveFile() {
    this.setState({
      file: null,
    });
  }

  handleResetFile() {
    this.setState({
      file: null,
    });
  }

  handleViewChanger(view) {
    this.setState({
      view,
    });
  }
  render() {
    return (
      <>
        {this.state.isLoading ? (
          <Loading className="loader-fullscreen" />
        ) : (
          <>
            <div className="col-md-8">
              <div className="request-view-container">
                <DashboardHeader
                  isLoading={this.state.isLoading}
                  title={this.state?.data?.description}
                  requestNumber={this.state?.data?._id}
                  maxResponseTime={this.state?.data?.service?.maxResponseTime}
                  status={this.state?.data?.status}
                />
                <hr />
                {/* initiator info */}
                <div className="card-body">
                  <UserSMProfile
                    firstName={this.state?.data?.user?.firstName}
                    lastName={this.state?.data?.user?.lastName}
                    department={this.state?.data?.user?.department}
                    position={this.state?.data?.user?.position}
                    institution={this.state?.data?.user?.institution}
                    actionStatus={
                      language[this.props.defaultLanguage].initiator
                    }
                    date={this.state?.data?.createdAt}
                  />
                </div>
                {/* render questionnaire answers */}
                {this.state.questionnaireAnswers.length > 0 && (
                  <div className="card" style={{ marginTop: "1.5rem" }}>
                    <div className="card-header d-flex align-items-center">
                      <div style={{ flex: 1 }}>
                        <h3 className="text-bold">
                          {language[this.props.defaultLanguage].request}
                        </h3>
                      </div>
                      <div className="questionnaire-card-view-actions-buttons">
                        <div
                          onClick={this.handleViewChanger.bind(this, "list")}
                          className={`item ${
                            this.state.view === "list" && "active"
                          } `}
                        >
                          <span className={process.env.REACT_APP_ICONS_TYPE}>
                            {icons.list}
                          </span>
                        </div>
                        <div
                          onClick={this.handleViewChanger.bind(this, "grid")}
                          className={`item ${
                            this.state.view === "grid" && "active"
                          }`}
                        >
                          <span className={process.env.REACT_APP_ICONS_TYPE}>
                            {icons.grid}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <QuestionnaireAnswers
                          data={this.state.questionnaireAnswers}
                          styles={
                            this.state.view === "grid"
                              ? "col-md-6"
                              : "col-md-12"
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
                {/* render attachments answers */}
                {console.log("this.state.attachments", this.state.attachments)}
                {this.state.attachments.length > 0 && (
                  <div className="card" style={{ marginTop: "1.5rem" }}>
                    <div className="card-header">
                      <span className="text-bold">
                        {language[this.props.defaultLanguage].attachments} (
                        {this.state.attachments.length})
                      </span>
                    </div>
                    <div className="card-body">
                      <Attachments data={this.state.attachments} />
                    </div>
                  </div>
                )}
                {/* render approvals actions */}
                {/* {this.state.showApproverActions && ( */}
                <div className="card" style={{ marginTop: "1.5rem" }}>
                  <div className="card-body">
                    <Comment
                      data={this.state.data}
                      comments={this.state.comments}
                      commentText={this.state.commentText}
                      onChangeCommentText={(e) =>
                        this.onChangeText("commentText", e)
                      }
                      error={this.state.error}
                      showApproverActions={this.state.showApproverActions}
                      requestStatus={this.state.data.status}
                      approver={this.state.approver}
                      fileUploadAction={this.fileUploadAction.bind(this)}
                      file={this.state.file}
                      handleRemoveFile={this.handleRemoveFile.bind(this)}
                      handleShowRejectModal={() =>
                        this.handleShowModal(
                          this.state?.user?.accountType ===
                            "account-subApprover"
                            ? "showSubApproverRejectModal"
                            : "showApproverRejectModal"
                        )
                      }
                      handleShowApproveModal={() =>
                        this.handleShowModal(
                          this.state?.user?.accountType ===
                            "account-subApprover"
                            ? "showSubApproverApproveModal"
                            : "showApproverApproveModal"
                        )
                      }
                      request={this.state.data}
                      requestId={this.state.data._id}
                      getData={this.getData.bind(this)}
                      comment={this.state.commentText}
                      resetFile={this.handleResetFile.bind(this)}
                    />
                  </div>
                </div>
                {/* )} */}
              </div>
            </div>
            <div className="col-md-4">
              {this.state.isLoading ? (
                <Loading className="fullscreen" />
              ) : (
                this.state.data._id && <Sidebar {...this.state} />
              )}
            </div>
          </>
        )}
        {/* approvers modals */}
        <Modal
          handleClose={this.handleCloseModal.bind(
            this,
            "showApproverApproveModal"
          )}
          show={this.state.showApproverApproveModal}
        >
          <ApproverApprove
            request={this.state.data}
            requestId={this.state.data._id}
            approver={this.state.approver}
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showApproverApproveModal"
            )}
            getData={this.getData.bind(this)}
            comment={this.state.commentText}
            file={this.state.file}
            resetFile={this.handleResetFile.bind(this)}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(
            this,
            "showApproverRejectModal"
          )}
          show={this.state.showApproverRejectModal}
        >
          <ApproverReject
            request={this.state.data}
            requestId={this.state?.data?._id}
            approver={this.state.approver}
            handleShowApproveModal={() => alert(this)}
            handleShowRejectModal={() =>
              this.handleShowModal(
                this.state?.user?.accountType === "account-subApprover"
                  ? "showSubApproverRejectModal"
                  : "showApproverRejectModal"
              )
            }
            getData={this.getData.bind(this)}
            comment={this.state.commentText}
            file={this.state.file}
            resetFile={this.handleResetFile.bind(this)}
          />
        </Modal>

        {/* sub approvals modal */}
        <Modal
          handleClose={this.handleCloseModal.bind(
            this,
            "showSubApproverApproveModal"
          )}
          show={this.state.showSubApproverApproveModal}
        >
          <SubApproverApprove
            request={this.state.data}
            requestId={this.state.data._id}
            approver={this.state.approver}
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showSubApproverApproveModal"
            )}
            getData={this.getData.bind(this)}
            comment={this.state.commentText}
            file={this.state.file}
            resetFile={this.handleResetFile.bind(this)}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(
            this,
            "showSubApproverRejectModal"
          )}
          show={this.state.showSubApproverRejectModal}
        >
          <SubApproverReject
            request={this.state.data}
            requestId={this.state?.data?._id}
            approver={this.state.approver}
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showSubApproverRejectModal"
            )}
            getData={this.getData.bind(this)}
            comment={this.state.commentText}
            file={this.state.file}
            resetFile={this.handleResetFile.bind(this)}
          />
        </Modal>

        {/* //attachment modal */}
        {/* <Modal
          handleClose={this.handleCloseModal.bind(this, "showAttachmentsModal")}
          show={this.state.showAttachmentsModal}
          title={language[this.props.defaultLanguage].attach_file}
        >
          <UploadAttachment
            request={this.state.data}
            requestId={this.state?.data?._id}
            approver={this.state.approver}
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showAttachmentsModal"
            )}
            getData={this.getData.bind(this)}
            comment={this.state.commentText}
          />
        </Modal> */}
        <input
          onChange={this.handleFileChange.bind(this)}
          multiple={false}
          ref={this.fileRef}
          type="file"
          hidden
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(SingleRequest);
