import React from "react";
import { DoughnutChart } from "../Charts";
import { connect } from "react-redux";
import language from "../../language";
import { fetchRankAnalytics } from "../../utils/queries/analyticsQuery";
import moment from "moment";
import { onCountRequestPerType } from "../../actions/Request";

let total = {
  individual: 0,
  institution: 0,
};

class Analytics extends React.Component {
  state = {
    data: [],
    individualData: {},
    institutionData: [],
  };

  componentDidMount() {
    this.getIndividualRequestsData(true);
    this.getInstitutionRequestsData(true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.getIndividualRequestsData(true);
      this.getInstitutionRequestsData(true);
    }
  }

  getIndividualRequestsData = async (isLoadingInd) => {
    try {
      const { defaultLanguage, filters } = this.props;
      const { startDate, endDate, services } = filters;

      let labels = [],
        chartData = [];

      this.setState({ isLoadingInd });

      const data = await fetchRankAnalytics(defaultLanguage, {
        order: "status",
        ["body.service.type"]: "individual",
        services,
        startCreatedAt: moment(startDate).unix() * 1000,
        endCreatedAt: moment(endDate).endOf("day").unix() * 1000,
      });

      let color = [];

      for (let i = 0; i < data.length; i++) {
        if (data[i]?._id?.toLowerCase() === "pending") {
          color.push("#CBE0F2");
          total.individual = data[i].count;
        }

        if (data[i]?._id?.toLowerCase() === "approved") {
          color.push("#078ECE");
        }

        if (data[i]?._id?.toLowerCase() === "rejected") {
          color.push("#FF6D00");
        }
        labels.push("(" + data[i].count + ") " + data[i]?._id?.toUpperCase());
        chartData.push(data[i].count);
      }

      this.props.dispatch(onCountRequestPerType(total));

      this.setState({
        isLoadingInd: false,
        individualData: {
          labels,
          datasets: [
            {
              data: chartData,
              borderRadius: 15,
              backgroundColor: color,
              borderColor: color,
              barPercentage: 0.2,
            },
          ],
        },
      });
    } catch (error) {
      this.setState({
        isLoadingInd: false,
      });
    }
  };

  getInstitutionRequestsData = async (isLoadingInst) => {
    try {
      const { defaultLanguage, filters } = this.props;
      const { services, startDate, endDate } = filters;
      let labels = [],
        chartData = [];

      this.setState({ isLoadingInst });

      const data = await fetchRankAnalytics(defaultLanguage, {
        order: "status",
        ["body.service.type"]: "institution",
        services,
        startCreatedAt: moment(startDate).unix() * 1000,
        endCreatedAt: moment(endDate).endOf("day").unix() * 1000,
      });

      let color = [];

      for (let i = 0; i < data.length; i++) {
        if (data[i]?._id?.toLowerCase() === "pending") {
          color.push("#CBE0F2");
          total.institution = data[i].count;
        }

        if (data[i]?._id?.toLowerCase() === "approved") {
          color.push("#078ECE");
        }

        if (data[i]?._id?.toLowerCase() === "rejected") {
          color.push("#FF6D00");
        }
        labels.push("(" + data[i].count + ") " + data[i]?._id?.toUpperCase());
        chartData.push(data[i].count);
      }

      this.props.dispatch(onCountRequestPerType(total));

      this.setState({
        isLoadingInst: false,
        institutionData: {
          labels,
          datasets: [
            {
              data: chartData,
              borderRadius: 15,
              backgroundColor: color,
              borderColor: color,
              barPercentage: 0.2,
            },
          ],
        },
      });
    } catch (error) {
      this.setState({
        isLoadingInst: false,
      });
    }
  };

  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-6">
            <div className="card" style={{ marginBottom: "1rem" }}>
              <DoughnutChart
                title={language[this.props.defaultLanguage].individual_requests}
                data={this.state.individualData}
                isLoading={this.state.isLoadingInd}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <DoughnutChart
                title={
                  language[this.props.defaultLanguage].institution_requests
                }
                data={this.state.institutionData}
                isLoading={this.state.isLoadingInst}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(Analytics);
