import React from "react";
import { withRouter } from "../../../utils/withRouter";
import { DashboardHeader } from "../../../components/Header";
import { connect } from "react-redux";
import serviceQuery from "../../../utils/queries/serviceQuery";
import requestQuery from "../../../utils/queries/requestQuery";
import {
  Footer,
  Questionnaire,
  RequiredDocuments,
} from "../../../components/UserRequests";
import language from "../../../language";
import externalUserInfoQuery from "../../../utils/queries/externalUserInfoQuery";
import { getStorage } from "../../../utils/storage";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import { Loading } from "../../../components/Loader";
import { v4 as uuidv4 } from "uuid";
import { calculateFileSize } from "../../../utils/fileSize";
import isHR from "../../../utils/isHR";
import { Empty } from "../../../components/Empty";
import icons from "../../../constants/icons";
import { serviceHasIntegration } from "../../../utils/ippisAccountMandatory";
import { Login } from "../../../components/Login";
import { Modal } from "../../../components/Modal";
import { AccountSwitch } from "../../../components/AccountSwitch";
import isJsonString from "../../../utils/isJsonString";
class RequestServiceScreen extends React.Component {
  state = {
    isLoading: true,
    data: {},
    error: {},
    ippisNumber: "",
    institution: "",
    profileData: {},
    firstName: "",
    user: {},
    questionnaireAnswer: [],
    numberForms: 1,
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });

    await this.getData(true);

    this.getRequestInfo(true);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.profileData !== this.state.profileData) {
      this.setState({
        institution: this.state.profileData.institution,
        ippisNumber: this.state.profileData.employeeId,
      });

      this.state.data?.questionnaire?.forEach((el) => {
        if (el.answerType === "position") {
          this.setState({
            [el.questionText]: this.state.profileData.position,
          });
        }

        if (el.answerType === "department") {
          this.setState({
            [el.questionText]: this.state.profileData.department,
          });
        }
      });
    }
  }

  fromInstitutionChecker = async () => {
    const user = await getStorage();
    const { data } = this.state;

    if (data.type === "institution" && !user.fromInstitution) {
      this.handleShowModal("showFromInstitutionModal");
    }
  };

  getData = async (isLoading) => {
    try {
      const { defaultLanguage, routeParams } = this.props;
      const { serviceId } = routeParams;

      this.setState({ isLoading });

      const data = await serviceQuery(defaultLanguage, {
        id: serviceId,
      });

      let res = {};

      if (data?.length > 0) {
        res = data[0];
      }

      this.setState({
        data: res,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  getRequestInfo = async (isLoading) => {
    const { defaultLanguage, routeParams } = this.props;
    const { questionnaireAnswer } = this.state;
    const serviceInfo = this.state.data;

    const { requestId } = routeParams;

    if (!requestId) return;
    try {
      this.setState({ isLoading });

      const { data } = await requestQuery(defaultLanguage, {
        id: requestId,
      });

      let res = {};

      // if (!data.files) {
      //   serviceInfo["files"] = [];
      // }

      if (data?.length > 0) {
        res = data[0];
      }
      serviceInfo.files &&
        serviceInfo.files.map((fel) => {
          res.questionnaireAnswer.forEach((el) => {
            if (el.answerType === "file" && fel.name === el.questionText) {
              const url = el.answer;

              // fel["uploadedFiles"] = [
              //   {
              //     answer: el.answer,
              //     name: el.questionText,
              //     url,
              //   },
              // ];
              // {
              //   file: [],
              //   name: el.questionText,
              //   uploadedFiles:
              //   _id: el._id,
              // };
            }
          });
          return fel;
        });

      console.log({ serviceInfo });

      serviceInfo.questionnaire &&
        serviceInfo.questionnaire.forEach((qA) => {
          res.questionnaireAnswer.forEach((answer) => {
            if (
              qA.questionText === answer.questionText &&
              answer.answerType !== "file"
            ) {
              if (questionnaireAnswer.length == 0) {
                questionnaireAnswer.push({
                  [qA._id]: {
                    answer: isJsonString(answer.answer)
                      ? JSON.parse(answer.answer)
                      : answer.answer,
                    answerType: answer.answerType,
                    questionText: answer.questionText,
                  },
                });
              } else {
                questionnaireAnswer.forEach((qAEl, i) => {
                  if (!qAEl[qA._id]) {
                    qAEl[qA._id] = {
                      answer: isJsonString(answer.answer)
                        ? JSON.parse(answer.answer)
                        : answer.answer,
                      answerType: answer.answerType,
                      questionText: answer.questionText,
                    };
                  }
                });
              }
            }
          });
        });

      this.setState({
        isLoading: false,
        data: serviceInfo,
        questionnaireAnswer,
      });
    } catch (error) {
      console.error(error);
      this.setState({
        isLoading: false,
      });
    }
  };

  retrieveIPPISInfo() {
    let { error, ippisNumber, firstName, data } = this.state;

    delete error[ippisNumber];
    delete error[firstName];
    delete error["institution"];

    if (ippisNumber === "") {
      error.ippisNumber =
        language[this.props.defaultLanguage].ippis_number_required;
    }

    if (firstName === "") {
      error.firstName =
        language[this.props.defaultLanguage].first_name_required;
    }

    this.setState({ error }, () => {
      if (Object.keys(this.state.error).length === 0) {
        externalUserInfoQuery(this, {
          params: {
            ippisNumber: this.state.ippisNumber,
            firstName,
          },
        });
      }
    });
  }

  getData = async (isLoading) => {
    try {
      const { defaultLanguage, routeParams } = this.props;
      const { serviceId } = routeParams;

      this.setState({ isLoading });

      const data = await serviceQuery(defaultLanguage, {
        id: serviceId,
      });

      let res = {};

      if (data?.length > 0) {
        res = data[0];
      }

      this.setState({
        data: res,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  initiateQuestionnaireAnswer(params) {
    const { formKey, value, questionId, questionText, answerType } = params;

    let { questionnaireAnswer } = this.state;

    if (!questionnaireAnswer[formKey]) {
      questionnaireAnswer[formKey] = {};

      if (!questionnaireAnswer[formKey][questionId]) {
        if (value?.target) {
          questionnaireAnswer[formKey][questionId] = "";
        } else {
          questionnaireAnswer[formKey][questionId] = {};
        }
      }
    }

    if (value.target) {
      questionnaireAnswer[formKey][questionId] = {
        questionText,
        answer: value.target.value,
        answerType,
      };
    } else {
      value["questionText"] = questionText;
      value["answerType"] = answerType;
      value["answer"] = JSON.stringify(value);
      questionnaireAnswer[formKey][questionId] = value;
    }

    return questionnaireAnswer;
  }

  onChangeText(params) {
    try {
      const { formKey, questionId } = params;
      let { error } = this.state;

      const questionnaireAnswer = this.initiateQuestionnaireAnswer(params);

      delete error[`${questionId}_${formKey}`];
      delete error.fromInstitution;

      this.setState({ questionnaireAnswer, error });
    } catch (error) {
      console.log("questionnaire answers error***", error);
    }
  }

  handleFiles(index, filesData) {
    const fileList = Array.from(filesData);
    const { data, error } = this.state;
    const { files } = data || [];

    if (!files[index].uploadedFiles) {
      files[index].uploadedFiles = [];
    }

    const totalSize = calculateFileSize(fileList);
    const limit = 3 * 1024 * 1024; //  3MB

    if (fileList.length > 2 || files[index].uploadedFiles?.length > 2) {
      error[files[index].name] =
        language[this.props.defaultLanguage].limit_files;

      this.setState({ error });
      return;
    }

    if (totalSize > limit) {
      error[files[index].name] =
        language[this.props.defaultLanguage].exceed_file_size_limit;

      this.setState({ error });

      return;
    }

    fileList.forEach((file) => {
      // const newName = files[index].name.replaceAll(" ", "_");
      // const newFile = new File([file], newName, { type: file.type });
      console.log("file", file);

      files[index].uploadedFiles.push(file);
    });

    delete error[files[index].name];
    delete error["message"];
    delete error.fromInstitution;

    console.log(data);

    this.setState({ data });
  }

  handleRemoveFile({ parentIndex, fileIndex }) {
    const { data } = this.state;
    const { files } = data || [];

    delete files[parentIndex].uploadedFiles[fileIndex];

    this.setState({ data });
  }

  validateQuestionnaireForm() {
    try {
      let { questionnaireAnswer, data, error, numberForms } = this.state;
      const { questionnaire } = data;

      // check if no answered questionnaire
      if (questionnaireAnswer.length === 0 && questionnaire.length > 0) {
        for (let i = 0; i < numberForms; i++) {
          questionnaire.forEach((el) => {
            if (el?.singleAnswer?.required) {
              let errorQuestionSeparator = el._id + "_" + i;

              error[errorQuestionSeparator] = `${el.questionText} ${
                language[this.props.defaultLanguage].is_required
              }`;
            }
          });
        }
      }
      //validate answer 1 by 1
      else if (questionnaireAnswer.length > 0) {
        for (let i = 0; i < numberForms; i++) {
          questionnaire.forEach((el) => {
            const questionAnswer = questionnaireAnswer.find((qAns) =>
              Object.keys(qAns).includes(el._id)
            );

            let errorQuestionSeparator = el._id + "_" + i;

            if (!questionAnswer && el?.singleAnswer?.required) {
              error[errorQuestionSeparator] = `${el.questionText} ${
                language[this.props.defaultLanguage].is_required
              }`;
            } else if (
              questionAnswer &&
              el?.singleAnswer?.required &&
              (!questionAnswer[el._id] ||
                (questionAnswer[el._id]?.answer &&
                  questionAnswer[el._id]?.answer === ""))
            ) {
              error = `${el.questionText} ${
                language[this.props.defaultLanguage].is_required
              }`;
            }
          });
        }
      }

      this.setState({ error });
    } catch (error) {
      console.log("validation error: ", error);
    }
  }

  validateDocumentForm() {
    const { error, data } = this.state;
    const { files } = data;
    const limit = 5 * 1024 * 1024;

    files?.forEach((el) => {
      console.log(el);
      if (el.uploadedFiles === undefined || el?.uploadedFiles?.length === 0) {
        error[el.name] = language[this.props.defaultLanguage].document_required;
      } else {
        const totalSize = calculateFileSize(el.uploadedFiles);
        if (totalSize > limit) {
          error[el.name] =
            language[this.props.defaultLanguage].document_exceed_limit;
        }
      }
    });

    const totalSize = calculateFileSize(files);
    if (totalSize > limit) {
      error["message"] =
        language[this.props.defaultLanguage].document_exceed_limit;
    }

    this.setState({ error });
  }

  validateFromInstitution = async () => {
    const user = await getStorage();

    const { data } = this.state;

    if (user.fromInstitution && data.type === "institution") {
      this.handleShowModal("showFromInstitutionModal");
    }
  };

  onSubmit = async () => {
    try {
      await this.validateQuestionnaireForm();
      await this.validateDocumentForm();
      await this.validateFromInstitution();

      const { error } = this.state;

      const user = await getStorage();

      if (Object.keys(error).length === 0) {
        this.setState({ isSubmitting: true });

        const { data } = this.state;
        const { routeParams = {} } = this.props;
        let duplicateId = uuidv4();

        const { requestId } = routeParams;

        const formData = new FormData();

        const approvals =
          data?.approver?.map((el, i) => {
            let payload = {
              ...el,
              account: el?.account?._id || el.account,
            };

            return payload;
          }) || [];

        const subApprovals =
          data?.subApprover?.map((el) => {
            // if (!fromInstitution.includes(el?.account?.institution)) {
            //   fromInstitution.push(el?.account?.institution);
            // }

            let payload = {
              ...el,
              account: el?.account?._id || el.account,
            };

            return payload;
          }) || [];

        const requestBody = {
          category: data.category._id,
          description: data.name,
          institution: data.institution,
          service: data._id,
          user: user.id,
          questionnaireAnswer: [],
          type: data.type || data?.category?.type,
          approvals,
          subApprovals,
        };

        if (requestId) {
          delete requestBody.approvals;
          delete requestBody.subApprovals;
        }

        if (data.type || data?.category?.type === "institution") {
          requestBody.subApprovalStatus = "pending";

          requestBody.fromInstitution = this.props.fromInstitution || [
            user.institution,
          ];
        }

        data?.files?.forEach((el, idx) => {
          el?.uploadedFiles?.map((uploadEl) => {
            if (uploadEl instanceof File) {
              let formateFileBody = {
                questionText: el.name,
                answerType: "file",
                duplicateId,
              };

              console.log("formateFileBody", formateFileBody);

              requestBody.questionnaireAnswer.push(formateFileBody);

              formData.append(
                `questionnaireAnswer[${idx}].answer`,
                uploadEl,
                uploadEl.name
              );
              formData.append(el.name, el.name);
            }
          });
        });

        for (let i = 0; i < this.state.numberForms; i++) {
          duplicateId = uuidv4();

          const indexedQuestionnaireAnswer = this.state.questionnaireAnswer[i];

          const answers = Object.keys(indexedQuestionnaireAnswer || {}).map(
            (key) => {
              return {
                questionText: indexedQuestionnaireAnswer[key].questionText,
                answer:
                  typeof indexedQuestionnaireAnswer[key].answer === "object"
                    ? JSON.stringify(indexedQuestionnaireAnswer[key].answer)
                    : indexedQuestionnaireAnswer[key].answer,
                answerType: indexedQuestionnaireAnswer[key].answerType,
                duplicateId,
              };
            }
          );

          requestBody.questionnaireAnswer =
            requestBody.questionnaireAnswer.concat(answers);
        }

        for (let el of Object.keys(requestBody)) {
          if (
            Array.isArray(requestBody[el]) ||
            typeof requestBody[el] === "object"
          ) {
            formData.append(el, JSON.stringify(requestBody[el]));
          } else {
            formData.append(el, requestBody[el]);
          }
          // formData.append(
          //   el,
          //   Array.isArray(requestBody[el])
          //     ? JSON.stringify(requestBody[el])
          //     : requestBody[el]
          // );
        }

        let method = "POST";

        if (
          this.props.routeParams.requestId &&
          this.props.routeParams.requestId !== ""
        ) {
          method = "PUT";
          formData.append("id", this.props.routeParams.requestId);
          formData.append("status", "pending");
        }

        const options = {
          method,
          url: `${process.env.REACT_APP_PSRP_BASE_API}/request`,
          data: formData,
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "application/x-www-form-url",
          },
        };

        // Display the key/value pairs
        for (var pair of formData.entries()) {
          console.log(pair[0] + ", " + pair[1]);
        }

        await axios(options);

        toastMessage(
          "success",
          language[this.props.defaultLanguage].success_add_request
        );

        this.setState({ isSubmitting: false });

        window.location.href = "/public-servant/my requests";
      }
    } catch (error) {
      this.setState({ isSubmitting: false });

      console.log(error);

      toastMessage(
        "error",
        language[this.props.defaultLanguage].error_add_request
      );
    }
  };

  updateNumberForms(numberForms) {
    console.log("====================================");
    console.log(numberForms);
    console.log("====================================");
    this.setState({ numberForms });
  }

  handleShowModal(modal) {
    this.setState({
      [modal]: true,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  render() {
    console.log(this.state);
    return (
      <>
        <DashboardHeader
          isLoading={this.state.isLoading}
          name={this.state.data.name}
          category={this.state?.data?.category?.name}
          maxResponseTime={this.state?.data?.maxResponseTime}
        />
        {this.state.isLoading ? (
          <div
            style={{
              width: "100%",
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loading />
          </div>
        ) : (
          <div className="col-md-11 mt-2 mb-3">
            <div>
              {this.state.data.type === "institution" &&
              !isHR(this.state.user) ? (
                <Empty
                  styles={{ height: "80vh" }}
                  icon={icons.service}
                  title={
                    language[this.props.defaultLanguage].not_allowed_to_apply
                  }
                  description={
                    language[this.props.defaultLanguage].consult_with_admin
                  }
                  actionButton={language[this.props.defaultLanguage].go_back}
                  handleActionButton={() =>
                    (window.location.href = `/public-servant/request/${this.props.routeParams.serviceId}`)
                  }
                />
              ) : serviceHasIntegration(
                  this.state?.data?.questionnaire || []
                ) && !this.state?.user?.ippisUserData ? (
                <Empty
                  styles={{ height: "80vh" }}
                  icon={icons.user}
                  title={language[this.props.defaultLanguage].ippis_auth}
                  description={
                    language[this.props.defaultLanguage].provide_ippis_account
                  }
                  actionButton={
                    language[this.props.defaultLanguage].ippis_login
                  }
                  handleActionButton={() =>
                    this.handleShowModal("showIPPISLoginModal")
                  }
                />
              ) : (
                <>
                  {this.state?.data?.questionnaire?.length > 0 && (
                    <div>
                      <Questionnaire
                        form={this.state?.data?.questionnaire || []}
                        onChange={this.onChangeText.bind(this)}
                        retrieveIPPISInfo={this.retrieveIPPISInfo.bind(this)}
                        {...this.state}
                        questionnaireAnswer={this.state.questionnaireAnswer}
                        error={this.state.error}
                        numberForms={this.state.numberForms}
                        updateNumberForms={this.updateNumberForms.bind(this)}
                      />
                    </div>
                  )}

                  {this.state?.data?.files?.length > 0 && (
                    <div className="mb-3">
                      <RequiredDocuments
                        files={this.state?.data?.files || []}
                        handleFiles={this.handleFiles.bind(this)}
                        handleRemoveFile={this.handleRemoveFile.bind(this)}
                        error={this.state.error}
                      />
                    </div>
                  )}
                  {(this.state?.data?.questionnaire?.length > 0 ||
                    this.state?.data?.files?.length > 0) && (
                    <div className="card card-shadow">
                      <Footer
                        onSubmit={this.onSubmit.bind(this)}
                        isSubmitting={this.state.isSubmitting}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showIPPISLoginModal")}
          show={this.state.showIPPISLoginModal}
          title="IPPIS Login"
        >
          <Login
            redirectURL={`/public-servant/request/${this.props.routeParams.serviceId}`}
            styles={{ boxShadow: "none" }}
            formContainerSize={12}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(
            this,
            "showFromInstitutionModal"
          )}
          show={this.state.showFromInstitutionModal}
          title={language[this.props.defaultLanguage].from_institution}
        >
          <AccountSwitch className="card-body" />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(RequestServiceScreen));
