import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Tabs } from "../Tabs";
import { DashboardHeader } from "../Header";
import requestQuery from "../../utils/queries/requestQuery";
import RequestInfo from "./RequestInfo";
import QuestionnaireAnswer from "../AdminRequests/QuestionnaireAnswer";
import Documents from "../AdminRequests/Documents";
import { onRemoveNotification } from "../../actions/Notification";
import { updateNotification } from "../../utils/queries/notificationQuery";
import { getStorage } from "../../utils/storage";
import Comments from "./Comments";
import ApproversResponse from "./ApproversResponse";

class SingleRequest extends React.Component {
  state = {
    isLoading: false,
    page: 1,
    limit: 1,
    data: {},
    selected_data: [],
  };

  componentDidMount() {
    this.getData(true);
  }

  handleShowModal(modal, modalTitle, selected_data = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_data,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage, requestId } = this.props;

      this.setState({ isLoading });

      //read notification: check if notification exist in notification storage redux, then remove it, this will count down notifications
      this.props.dispatch(onRemoveNotification(requestId));

      const { data } = await requestQuery(defaultLanguage, {
        page,
        limit,
        id: requestId,
      });

      let res = {};

      if (data?.length > 0) {
        res = data[0];
      }

      this.setState({
        data: res,
        isLoading: false,
      });

      this.handleUpdateNotification();
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleUpdateNotification = async () => {
    const { notifications, requestId, defaultLanguage } = this.props;
    const user = await getStorage();

    const notificationItem = notifications.find((notificationEl) => {
      if (notificationEl.bodyID === requestId) return notificationEl;
    });

    if (notificationItem && notificationItem.notificationID) {
      const { viewedBy = [] } = notificationItem;

      if (user.id && !viewedBy.includes(user.id)) {
        viewedBy.push(user.id);
      }

      updateNotification({
        lng: defaultLanguage,
        id: notificationItem.notificationID,
        viewedBy,
      });
    }
  };

  render() {
    const { request_information, required_document, questionnaire, comments } =
      language[this.props.defaultLanguage];

    let options = [
      {
        title: request_information,
        data: (
          <RequestInfo
            data={[this.state?.data]}
            isLoading={this.state.isLoading}
          />
        ),
      },
      {
        title: language[this.props.defaultLanguage].approvals,
        data: (
          <ApproversResponse
            data={[
              ...(this.state?.data?.approvals || []),
              ...(this.state?.data?.subApprovals || []),
            ]}
            isLoading={this.state.isLoading}
          />
        ),
      },
      {
        title: questionnaire,
        data: (
          <QuestionnaireAnswer
            data={this.state?.data?.questionnaireAnswer}
            requestId={this.props.requestId}
          />
        ),
      },
      {
        title: required_document,
        data: <Documents data={this.state?.data?.questionnaireAnswer} />,
      },
    ];

    if (
      this.state?.data.subApprovalStatus === "rejected" ||
      this.state?.data.status === "rejected"
    ) {
      options.push({
        title: comments,
        data: (
          <Comments
            subApprovalsComments={this.state?.data?.subApprovals || []}
            approvalsComments={this.state?.data?.approvals || []}
          />
        ),
      });
    }

    return (
      <div className="card card-dashboard-content">
        <DashboardHeader
          isLoading={this.state.isLoading}
          title={this.state?.data?.description}
          requestNumber={this.state?.data?._id}
          maxResponseTime={this.state?.data?.service?.maxResponseTime}
          status={this.state?.data?.status}
          actionButton={
            this.state.data.status === "requestedChanges" &&
            language[this.props.defaultLanguage].edit_request
          }
          handleActionButton={() =>
            (window.location.href = `/public-servant/request/${this.state.data?.service?._id}/${this.state.data?._id}`)
          }
        />
        <div className="card card-dashboard-content">
          <div className="card-body">
            <Tabs options={options} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { notifications } = state.Notifications;
  return {
    defaultLanguage,
    notifications,
  };
};

export default connect(mapStateToProps)(SingleRequest);
